.sliding-puzzle-figure {
  margin: auto;
  height: 650px;
  font-size: 24px;
  width: 650px;
  max-height: 60vh;
  max-width: 60vh;
  touch-action: none;
  /*padding-bottom: 50vh;*/
  /*padding-top: 10vh; }*/
  }
  .sliding-puzzle-figure a {
    cursor: pointer; }
    .sliding-puzzle-figure a#shuffle {
      color: #E74C3C; }
    .sliding-puzzle-figure a#solve {
      color: #3498DB; }
  .sliding-puzzle-figure .sliding-puzzle {
    list-style-type: none;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-clip: border-box;
    /* Firefox 4, Safari 5, Opera 10, IE 9 */
    border: 18px solid #2C3E50;
    border-radius: 10px;
    background-color: #2C3E50; }
    .sliding-puzzle-figure .sliding-puzzle .tile {
      position: absolute;
      touch-action: none;
      background-color: #7BC8F3;
      color: #1F1F1F;
      border-radius: 10px;
      cursor: pointer;
      width: 31%;
      height: 31%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 60px;
      left: 0%;
      top: 0%;
      transition: all 0.5s linear;
      transition-timing-function: ease;
      box-sizing: border-box; }
      .sliding-puzzle-figure .sliding-puzzle .tile.error {
        background-color: #F0867D;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }

.tileImage {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 650px) {
  .sliding-puzzle-figure {
    width: 90vw;
    height: 90vw;
    max-height: 100vh; }
    .sliding-puzzle-figure .sliding-puzzle {
      border-width: 10px;
      border-radius: 14px; }
    .sliding-puzzle-figure .tile {
      font-size: 1em; } }

/*# sourceMappingURL=style.css.map */
